import * as React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal } from 'react-bootstrap'
import ReceivableCsv from './receivables/ReceivableBySettlementDateCsv'
import ReceivableByTransactionDateCsv from './receivables/ReceivableByTransactionDateCsv'
import { Fragment } from 'react'
import ReconciliationCsv from './reconciliation/ReconciliationCsv'
import ReconciliationCsvV2 from './reconciliation/ReconciliationCsvV2'
import ReconciliationSearchCsv from './reconciliation/ReconciliationSearchCsv'
import ChargebackCsv from './chargeback/ChargebackCsv'
import ReceivableDiffBySettlementDate from './receivables/ReceivableDiffBySettlementDate'
import KycCsv from './kyc/KycCsv'
import PayoutReconciliationCsv from './reconciliation/PayoutReconciliationCsv'
import GuaranteeDepositReleaseCsv from './reconciliation/GuaranteeDepositReleaseCsv'
import LlOperationPaymentCollection from './reconciliation/LlOperationPaymentCollection'
import UploadFile from '../../components/UploadFile'
import {
  createChargebacksUsingEmailFile,
  updateChargebackUsingRedePortalFile,
  updateChargebackUsingRedePortalFileWithEmailFile,
  updateChargebackUsingFallbackFile,
} from '../../services/APIClient'
import ChargebackUploadPortalWithEmailFile from './chargeback/ChargebackUploadPortalWithEmailFile'

export function ReceivableModal() {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Fragment>
      <Button className="btn-success" onClick={handleOpen}>
        Receivable Reports
      </Button>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Receivable</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ReceivableCsv />
          </div>
          <div>
            <ReceivableByTransactionDateCsv />
          </div>
          <div>
            <ReceivableDiffBySettlementDate />
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export function ReconciliationModal() {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Button className="btn-info" onClick={handleOpen}>
        Reconciliation Reports
      </Button>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reconciliation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ReconciliationCsv />
          </div>
          <div>
            <ReconciliationSearchCsv />
          </div>
          <div>
            <ReconciliationCsvV2 />
          </div>
          <div>
            <LlOperationPaymentCollection />
          </div>
          <div>
            <GuaranteeDepositReleaseCsv />
          </div>
          <div>
            <PayoutReconciliationCsv />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export function ChargebackModal() {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Button onClick={handleOpen}>Chargeback reports</Button>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Chargeback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <UploadFile
              fileDescription="Upload the chargeback csv received via email by REDE to create chargebacks."
              uploadFunction={createChargebacksUsingEmailFile}
            />
          </div>
          <div>
            <UploadFile
              fileDescription="Upload the chargeback csv exported from REDE Portal to update chargebacks to DISPUTED_BY_MERCHANT or CANCELED_BY_CONSUMER or CANCELED_BY_ACQUIRER."
              uploadFunction={updateChargebackUsingRedePortalFile}
            />
          </div>
          <div>
            <ChargebackUploadPortalWithEmailFile
              fileDescription="Upload the chargeback csv exported from REDE Portal and csv received via email by REDE to update chargebacks to DISPUTE_LOST or CHARGEBACK_REFUNDED.
               It is also possible to upload just the REDE Portal file in case of no email received."
              uploadFunction={updateChargebackUsingRedePortalFileWithEmailFile}
            />
          </div>
          <div>
            <UploadFile
              fileDescription="Upload the chargeback csv using our fallback template to update chargebacks to DISPUTE_WON or any other status than OPENED."
              uploadFunction={updateChargebackUsingFallbackFile}
            />
          </div>
          <div>
            <ChargebackCsv />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export function KycModal() {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Button className="btn btn-warning" onClick={handleOpen}>
        Know your customer reports
      </Button>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Know your customer reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <KycCsv />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
